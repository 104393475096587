<template>
  <div id="bg">
    <search-pic :type="type">
      <div style="display: flex">
        <div class=" left-box">
          <div>
            <dv-border-box-12 style="padding: 10px ">
              <div id="main" style="height: 300px">111111111</div>
            </dv-border-box-12>
          </div>


          <div>
            <dv-border-box-12 style="padding: 10px;height: 600px ;">
              <div
                id="rank"
                style="height: 600px"
              ></div>
            </dv-border-box-12>
          </div>
        </div>
        <div class="center-box">
          <center></center>
          <centerceft2chart width="100%" height="560px"></centerceft2chart>
        </div>
        <div class="right-box">
          <div>
            <dv-border-box-12 style="padding: 10px;height: 320px ">
              <center-right1></center-right1>
            </dv-border-box-12>
          </div>
          <div>
            <dv-border-box-12 style="padding: 10px;height: 600px ">
              <roll></roll>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </search-pic>
  </div>
</template>

<script>
  import SearchPic from "@/views/template/SearchPic";
  import centerceft2chart from "@/components/echart/centerLeft/centerLeft2Chart";
  import center from "@/views/bigScreen/center";
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";
  import centerRight1 from "@/views/bigScreen/centerRight1";
  import Roll from "@/views/bigScreen/Roll";
  export default {
    components:{SearchPic,Roll,centerceft2chart,center,centerRight1},
    data() {
      return{
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
        },
        type: {
          axisBar: "axisBar",//折线柱状图
          defaultLine: "defaultLine",//默认的折线图
          radarchart: "RadarChart",//雷达图
          sankey: "sankey",//桑基图
          scatter: "scatter",//气泡图
          stackCharts: "stackCharts",
          sunburst: "sunburst",
          wordcloud: "wordcloud",
          ybar: "ybar",
          pie:"pie",
          bar:"bar",
        },
        option:{
          indicator:{},
        },
      };
    },
    mounted() {
      setTimeout(()=>{
        this.getBrand();
        this.getRank();
      },500);

    },
    methods:{
      getBrand() {
        var chartDom = document.getElementById("main");
        var myChart = echarts.init(chartDom,tdTheme);
        console.log(chartDom);
        const option = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: "5%",
            left: "center",
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "40",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                { value: 1048, name: "日立" },
                { value: 735, name: "新时达" },
                { value: 580, name: "戈尔" },
                { value: 484, name: "莫纳克" },
                { value: 300, name: "通力" },
              ],
            },
          ],
        };
        myChart.setOption(option);
      },
      getRank() {
        var chartDom = document.getElementById("rank");
        var myChart = echarts.init(chartDom,tdTheme);
        console.log(chartDom);
        const option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {},
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "category",
            data: ["张三", "李四", "王五", "赵六", "钱七"],
          },
          series: [
            {
              name: "保养",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: [320, 302, 301, 334, 390, 330, 320],
            },
            {
              name: "维修",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: [120, 132, 101, 134, 90, 230, 210],
            },
          ],
        };
        myChart.setOption(option);
      },
    },
  };
</script>

<style scoped>
#bg{
  width: 100%;
  height: 100%;
  padding: 16px 16px 0 16px;
  background-image: url("../../assets/pageBg.png");
  background-size: cover;
  background-position: center center;
}
.left-box{
  width: 30%;

}
.center-box{
  width: 39%;
}
.right-box{
  width: 30%;
}
</style>
