<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span style="size: 30px">工单完成率</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <vue-seamless-scroll :data="listData" :class-option="defaultOption" class="seamless-warp">
          <ul class="item">
            <li v-for="(item,index) in listData" :key="index">
              <el-card :body-style="{ padding: '0px' }" shadow="always" class="card" @click.native="goToMonitor(item.id)">
                <div class="cardTitle">{{item.title}}</div>
                <div class="content">{{item.area}}</div>
                <div class="content">{{item.name}}</div>
              </el-card>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        config: {
          data: [
            ["<div  class='colorGrass'>" +
              "<div>1号楼</div>" +
              "<div>新天地</div>"],
          ],
          rowNum: 3, //表格行数
          headerHeight: 35,
          headerBGC: "#0f1325", //表头
          oddRowBGC: "#0f1325", //奇数行
          evenRowBGC: "#171c33", //偶数行
          // index: true,
          // columnWidth: [50],
          align: ["center"],
          waitTime:1000,
        },
        optionHover:true,
        listData: [
          {
            "title": "班组一",
            "area": "金海滩花园",
            "name":"10%",
            id:57,
          },{
            "title": "班组二",
            "area": "金海滩花园",
            "name":"80%",
            id:57,
          },{
            "title": "班组三",
            "area": "金海滩花园",
            "name":"60%",
            id:57,
          },{
            "title": "班组四",
            "area": "金海滩花园",
            "name":"20%",
            id:57,
          },{
            "title": "班组五",
            "area": "金海滩花园",
            "name":"30%",
            id:57,
          },{
            "title": "班组刘",
            "area": "金海滩花园",
            "name":"20%",
            id:57,
          },{
            "title": "班组三",
            "area": "金海滩花园",
            "name":"60%",
            id:57,
          },{
            "title": "班组四",
            "area": "金海滩花园",
            "name":"20%",
            id:57,
          },{
            "title": "班组五",
            "area": "金海滩花园",
            "name":"30%",
            id:57,
          },{
            "title": "班组刘",
            "area": "金海滩花园",
            "name":"20%",
            id:57,
          },{
            "title": "班组三",
            "area": "金海滩花园",
            "name":"60%",
            id:57,
          },{
            "title": "班组四",
            "area": "金海滩花园",
            "name":"20%",
            id:57,
          },{
            "title": "班组五",
            "area": "金海滩花园",
            "name":"30%",
            id:57,
          },{
            "title": "班组刘",
            "area": "金海滩花园",
            "name":"20%",
            id:57,
          },
        ],
      };
    },
    computed: {
      defaultOption() {
        return {
          step: 1, // 数值越大速度滚动越快
          limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        };
      },

    },
    mounted() {},
    methods: {
      goToMonitor:function(id) {
        console.log(id);
        let route = this.$router.resolve(`/monitor/LiftMonitor?elevatorId=${id}`);
        window.open(route.href, "_blank");
      },
    },
  };
</script>

<style lang="scss" scoped>
$box-height: 600px;
$box-width: 100%;
#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .card{
    padding: 0;
    margin: 5px 0;
    background-image: url("../../assets/img/bigscreen/img_1.png");
    background-size: cover;
    background-color: #1a5cd7;
    opacity: 0.9;
    height: 100px;
  }
  .button{
    margin-right: 10px;
    //background-color: #ecf5ff;
  }
  .seamless-warp {
    height: 450px;
    //line-height: 20px;
    overflow: hidden;
  }
  .bg-color-black {
    //height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 100%;
      height: 200px;
    }
  }
  .status{
    display: flex;
    justify-content: space-around;
  }
  .primary{
    color:#00e0ff
  }
  .repair{
    color:#F56C6C
  }
  .maintain{
    color: #E6A23C;
  }
  .cardTitle{
    font-weight: bold;
    font-size: 20px;
    color: white;
    margin: 5px 10px;
  }
  .content{
    margin: 5px 10px;
    color: white;
  }
  .online{
    color: white;
    background-image:url(../../assets/img/bigscreen/isOnLine.png);
    background-size: cover;
    width: 80px;
    height: 20px;
    text-align: right;
    margin-right: 2px;
  }
}
</style>
